.content-tiles-row {
  &__details-list {
    background: #ffd157;
    border-radius: 20px;
    box-shadow: $text-shadow-sm;
    
    li {
      margin-bottom: 4px;
    }
  }
  
  img {
    border-radius: 20px;
    box-shadow: $text-shadow-sm;
  }

  &__amenities {
    background: #4682b4;
    color: $color-gray-dddd;
    font-weight: 400;

    p:not(:first-child) {
      margin-top: 1.5rem;
    }
  }
  
  &__amenities, &__attractions {
    border-radius: 20px;
    box-shadow: $text-shadow-sm;
  }
}
