.testimonials-container {
  background: $color-blue-ddd;
}

.testimonial {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 1px 2px 7px 2px rgba(255, 215, 0, 0.6);
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  border: 4px solid gold;

  .testimonial-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    padding: 1.8rem;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    svg {
      color: #fff;
      font-size: 24px;
    }
  }

  .testimonial-content {
    flex-grow: 1;
  }

  .testimonial-stars {
    margin-left: auto;
    min-width: 90px;

    svg {
      color: #fcd036;
      font-size: 11px;
   
      @media screen and (min-width: 800px) {
        font-size: 20px;
      }
      margin-right: 5px;
    }
  }


  .testimonial-text {
    // font-size: $font-size-sm;
    line-height: 1.4;
    margin-top: 1.5rem;
    padding-bottom: 1rem;
    text-indent: 15px;
  }

  .testimonial-author {
    font-size: 16px;
    font-weight: bold;
  }
}
