.image-gallery-container {
  background: white;
}

// .gallery-wrap {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 12px;
//   align-items: center;
//   justify-content: center;
//   padding: 0 1rem;
//   margin: 0 auto;

//   .single {
//     box-shadow: 3px 3px 4px rgba(0, 0, 0, .6);
//     cursor: pointer;
//     width: 300px;

//     img {
//       max-width: 100%;
//       min-width: 100%;
//     }

//     img:hover {
//       box-shadow: 2.5px 2.5px 4px rgba(0, 0, 0, .6);
//       transform: scale(1.02);
//     }
//   }
// }

.slider-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .btn-close, .btn-previous, .btn-next {
      position: fixed;
      cursor: pointer;
      opacity: .9;
      color: $color-white;
      z-index: 999;
    }

    .btn-close {
      right: 40px;
      top: 40px;
    }

    .btn-previous {
      left: 50px;
      top: 50%;
      transform: translateY(-50%);
    }

    .btn-next {
      right: 50px;
      top: 50%;
      transform: translateY(-50%);
    }
    
    .btn-next:hover, .btn-close:hover, .btn-previous:hover {
      opacity: 70%
    }

    .fullscreen {
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        pointer-events: none;
        user-select: none;
        -webkit-user-select: none; // Safari
        -ms-user-select: none; // IE10, IE11
      }
    }
}
