.calendars-container {
  &__subtotal {
    color: $color-black;
    font-size: $font-size-3xl;
  }

  &__down-arrow {
    &:hover {
      background: $color-gray-lllll;
      border-radius: $radius-sm;
      cursor: pointer;
      outline: 2px solid $color-black;
    }
  }
}
