.calendar {
  background: $color-white;
  border: 2.75px solid $theme-yellow-light;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.4);
  max-width: 280px;

  &__date-cell {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: $font-size-lg;
    justify-content: center;
    padding-bottom: 0.4rem;
    padding-top: 0.25rem;

    &--booked,
    &--unavailable {
      background: #fbd4d4;
      cursor: not-allowed !important;
    }

    &--invalid-hover {
      cursor: not-allowed !important;
    }

    &--admin {
      cursor: pointer !important;
    }

    &--is-past {
      background: $color-white !important;
      cursor: not-allowed;
      opacity: .4;
    }

    &--is-start-of-unavailable-range {
      background-image: linear-gradient(125deg, $color-white 50%, transparent 50%);
      cursor: pointer;
    }
    
    &--is-end-of-unavailable-range {
      background-image: linear-gradient(-55deg, $color-white 50%, transparent 50%);
      cursor: pointer;
    }

    border-radius: $radius-xs;

    &--selected {
      background: $color-green-l;

      p:last-child {
        color: $color-gray-dd;
      }
    }

    &--hovered {
      background: $color-green-ll;
      cursor: pointer !important;
    }

    &__price {
      font-size: $font-size-3xs;
      margin-top: 0.5rem;

      &--green {
        color: $color-green;
      }
      &--yellow {
        color: $color-yellow-dddd;
      }
      &--orange {
        color: $color-orange;
      }
      &--red {
        color: $color-red;
      }
      &--black {
        color: $color-black;
      }
    }
  }

  &__weekday-header {
    background: $color-blue-dddd;
    color: $color-white;
    padding: 0.5rem 0;
    text-align: center;
  }

  &__month-indicator {
    background: $theme-yellow-light;
    color: $color-black;
    font-weight: $font-weight-semibold;
    margin: 0 auto;
    max-width: 280px;

    h2 {
      margin-left: 0.25rem;
    }
  }

  &__table {
    margin: 0 auto;

    td {
      height: 40px;
      width: 40px;

      &.empty {
        background: $color-gray-llll;
      }

      &:not(.empty) {
        div {
          @include text-unselectable;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
