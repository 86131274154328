.button {
  background: $theme-yellow-light;
  border-radius: 50px;
  border: 2px solid $color-white;
  color: $color-black;
  font-size: 1.4rem;
  padding: 15px 30px;
  transition: all 0.15s ease-in-out;
  
  &:hover {
    background: #c60c30;
    color: $color-white;
  }
  
  &--shadow {
    box-shadow: .1px .2px 4px $color-white;

    &:hover {
      box-shadow: none;
    }
  }
  
  &--shadow-2 {
    box-shadow: .1px .2px 10px #a3a4b7;  
  }

  &--small {
    font-size: 1.25rem;
    padding: 7.5px 15px;
  }
}

.button--blue {
  background: $color-blue-d;
  border: 2.5px solid $color-white;
  color: $color-white;

  &:hover {
    background: #3472c7;
  }
}

.button--red {
  background: $theme-red-l;
  border: 2px solid $color-black;
  color: $color-white;

  &:hover {
    color: $color-white;
  }
}

.button--green {
  background: #8bc68c;
  border: 2px solid $color-black;
  color: $color-white;

  &:hover {
    background: #7cb07c;
  }
}

.button--yellow {
  background: #ffd157;
  border: 2px solid $color-black;
  color: $color-black;

  &:hover {
    background: #c60c30; 
    color: $color-white;
  }
}

.button--light {
  background: $color-spain-yellow;
  border: 2px solid $color-black;
  color: $color-black;
  transition: all 0.225s ease-in-out;
  
  &:hover {
    background: $color-spain-red;
    border: 2px solid $color-black;
    color: $color-white;
  }
}
