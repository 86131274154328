.text-container--collapsed {
  @media screen and (min-width: 0px) {
    max-height: 300px;
  }
  @media screen and (min-width: 768px) {
    max-height: 155px;
  }
  @media screen and (min-width: 800px) {
    max-height: 155px;
  }
  @media screen and (min-width: 900px) {
    max-height: 155px;
  }
  @media screen and (min-width: 1000px) {
    max-height: 155px;
  }
  @media screen and (min-width: 1100px) {
    max-height: 170px;
  }
  @media screen and (min-width: 1200px) {
    max-height: 200px;
  }
  @media screen and (min-width: 1300px) {
    max-height: 220px;
  }
  @media screen and (min-width: 1400px) {
    max-height: 250px;
  }
  @media screen and (min-width: 1500px) {
    max-height: 280px;
  }
  @media screen and (min-width: 1600px) {
    max-height: 300px;
  }
  @media screen and (min-width: 1700px) {
    max-height: 320px;
  }
  @media screen and (min-width: 1800px) {
    max-height: 340px;
  }
  @media screen and (min-width: 1900px) {
    max-height: 380px;
  }
}
