.main-landing {
  &__titles {
    color: white;
    
    h1 {
      @extend .font-7xl;
      font-family: 'Handlee', arial;
      font-weight: 600;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
      
      @media screen and (min-width: 768px) {
        font-size: 6.5rem;  
      }
    }

    
    h2 {
      @extend .font-6xl;
      font-family: 'Tangerine', cursive;
      margin-top: 1rem;
      color: $theme-yellow;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    }
  }

  &__contact-information {
    background: rgba(51, 51, 51, .85);
    border-radius: 11px;
    span {
      color: $color-yellow;
    }
    h4 {
      color: $color-white;
    }
    
    max-width: 800px;
    padding: 1.2rem 2.5rem;

    h1 {
      @extend .font-6xl;
      text-align: center;
    }
  }
}
