.navbar {
  background-color: $color-black;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  
  .nav-link {
    color: $theme-yellow;
    transition: color 0.3s ease;
    user-select: none;
    
    &:hover {
      color: #f0f0f0;
      cursor: pointer;
    }
  }
}

.nav-container {
  position: sticky;
  top: -2px;
  z-index: 888;
}

.nav-languages {
  a {
    transition: none;
  }

  svg {
    margin-right: 1.25rem;
  }
  
  .dropdown-menu {
    background: #212529;
    border: 1.5px solid #52565d;
  }
  
  .dropdown-item {
    color: #adb5bd;
    font-size: 1.5rem;

    &:hover {
      background: $theme-yellow;
      color: $color-black;
    }
  }
}
