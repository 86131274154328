.amenity-card {
  border-radius: 7px;
  border: none;
  color: $color-gray-dddd;
  font-size: $font-size-sm;
  height: 100%;
  text-shadow: 1px 1px 3px #FFF6B7;

  span {
    margin-left: 10px;
  }
}
