.main-details {
  background-color: #fffff0;
  color: $color-black;
  font-size: $font-size-lg;
  font-weight: 350;
  line-height: $line-height-lg;
  padding: 3.75rem 1rem;
  text-indent: 20px;

  p:not(:first-child) {
    margin-top: 2rem;
  }
}
